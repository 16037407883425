import { bffRetailMedia } from '~/middlewares/axios'

const baseURL = '/campaign/audience'

/**
 * List all available for campaign
 */
export const listAvailableAudienceService = (
  params?: ListAvailableAudienceQueryParams
) =>
  bffRetailMedia.get<ListAvailableAudienceResponse>(`${baseURL}/available`, {
    params
  })

/**
 * List campaigns by audience
 */
// GET https://api-retail-media.newtail.com.br/campaign/audience/:audience_id
export const listCampaignsByAudienceService = ({
  audienceId,
  params
}: ListCampaignsByAudienceService) =>
  bffRetailMedia.get<ListCampaignsByAudienceResponse>(
    `${baseURL}/${audienceId}`,
    { params }
  )
