import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../api'

const baseURL = `${baseAPI}/campaign`

// Adiciona os produtos relacionados na campanha
export const addRelatedProductServiceKey = 'add-related-product-service'
export const addRelatedProductService = ({
  body,
  id
}: {
  body: AddRelatedProductBody
  id: string
}) =>
  axios.post<ResponseAddRelatedProduct>(`${baseURL}/${id}/product/bulk`, body)

// Lista os produtos relacionados de campanha
export const listProductResultKey = 'list-product-result'
export const listProductResult = <TCount extends boolean>(
  campaignId: string,
  params: {
    count?: TCount
    quantity?: number
    page?: number
    product_id?: string
  }
) => {
  return axios.get<
    TCount extends true ? RelatedProductListResponse : RelatedProduct[]
  >(`${baseURL}/${campaignId}/product`, { params })
}

// Ativa ou desativa um produto de uma campanha
export const toggleProductStatusServiceKey = 'toggle-product-status-service'
export const toggleProductStatusService = ({
  productId,
  body
}: {
  productId: string
  body: ToggleProductStatusBody
}) =>
  axios.patch<ResponseToggleProductStatus>(
    `${baseURL}/product/${productId}`,
    body
  )
