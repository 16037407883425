import { formatViewCampaign } from '~/modules/retailMedia/dtos/common/campaign/get'

import { types } from '../constants'

const index = {
  pending: `${types.get}_PENDING`,
  fulfilled: `${types.get}_FULFILLED`,
  rejected: `${types.get}_REJECTED`
}

const reducerKey = 'item'

export const get = {
  [index.pending]: ({
    draft,
    action
  }: ActionData<null, { requestInBackground: boolean }>) => {
    const requestInBackground = !!action?.values?.requestInBackground

    if (!requestInBackground) {
      draft.industry.campaign[reducerKey].loading = false
      draft.industry.campaign[reducerKey].data = null
      draft.industry.campaign[reducerKey].error = null
    }
  },
  [index.fulfilled]: ({
    draft,
    action
  }: ActionData<IndustryCampaignViewResponse>) => {
    const formatedViewCampaign = formatViewCampaign(action.payload)

    draft.industry.campaign[reducerKey].data = formatedViewCampaign

    draft.industry.campaign.products.selectedProducts =
      formatedViewCampaign.selectedProducts

    draft.industry.campaign[reducerKey].loading = false
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.campaign[reducerKey].loading = false
    draft.industry.campaign[reducerKey].error =
      action?.payload?.data || action?.payload || true
  }
}
